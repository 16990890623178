<template>
  <v-app>
    <Sidebar />
    
    <v-main>
      <Modal :show="true"  />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Modal from './components/Modal.vue';
import Sidebar from './components/Sidebar.vue';

export default {
  name: 'App',
  components: {
    Sidebar,
    Modal,
  },
  data: () => ({
    //
  }),
};
</script>
