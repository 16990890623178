<template>
    <div v-if="isVisible" class="modal-overlay">
        <div class="modal-content">
            <h2 class="modal-title">{{ title }}</h2>
            <p v-html="message" class="modal-message"></p>
            <button @click="closeModal" class="modal-close-button">X</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        title: {
            type: String,
            default: "Aviso Importante!",
        },
        message: {
            type: String,
            default:
                `Olá, prezado usuário!

O site Conslegis foi desativado, mas não se preocupe, pois temos uma ótima novidade para você!
                    
Agora, todos os serviços e informações que você já conhece estão disponíveis em um <strong>novo endereço: https://conslegis.seg.es.gov.br. </strong><br>
No novo portal, você encontrará uma <strong>interface mais moderna, fácil de navegar </strong> e com diversas funcionalidades que tornam sua experiência ainda mais prática e eficiente.

Tudo isso foi pensado para continuar facilitando o acesso às informações que você precisa, de forma rápida e intuitiva. <br>
Agradecemos por sua compreensão e estamos certos de que você aproveitará ainda mais as facilidades do novo portal.<br>
Não deixe de conferir!`,
        },
        useLocalStorage: {
            type: Boolean,
            default: true,
        },
        localStorageKey: {
            type: String,
            default: "hasSeenModal",
        },
    },
    data() {
        return {
            isVisible: false,
        }
    },
    created() {
        if (this.useLocalStorage) {
            const hasSeenModal = localStorage.getItem(this.localStorageKey)
            if (!hasSeenModal) {
                this.showModal()
                localStorage.setItem(this.localStorageKey, "true")
            }
        } else {
            this.showModal()
        }
    },
    methods: {
        showModal() {
            this.isVisible = true
        },
        closeModal() {
            this.isVisible = false
        },
    },
}
</script>

<style>
.modal-overlay {
    position: fixed;
    top: 12%;
    left: 40%;
    transform: translateX(-17%);
    width: 80%;
    max-width: 800px;
    height: auto;
    max-height: 80%;
    background: rgb(32, 139, 170);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.modal-content {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    width: 98%;
    height: 90%;
}

.modal-title {
    margin-bottom: 10px;
}

.modal-message {
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 120%;
}

.modal-close-button {
    position: absolute;
    top: 6%;
    right: 4%;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #C86673;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}
</style>
